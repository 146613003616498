<template>
  <v-card class="elevation-0">
    <v-card-text>
      <v-alert type="info" class="font-weight-bold">
            <span>
              {{ $t('$vuetify.invitations.message[0]') }}
              {{ $route.params.lang == "en" ? '$' : '' }}<span style="font-size: 1.2em;">{{ profile.affiliate_wallet.dollar.balance.toFixed(2) }}
                {{ $route.params.lang == "en" ? '' : 'دلار' }}
                </span>
              {{ $t('$vuetify.invitations.message[1]') }}
            </span>
      </v-alert>

      <v-row align="center" justify="center" dense>
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-carousel
              cycle
              width="300"
              height="340"
              :show-arrows="false"
              style="direction:ltr"
              reverse
          >
            <v-carousel-item
                v-for="(slide, i) in slides"
                :key="i"
            >
              <v-img
                  :src="slide.src"

              ></v-img>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="8" class="text-center">
          {{ $t('$vuetify.invitationsPage.title') }}
        </v-col>
        <v-col cols="12" sm="8" md="6" class="text-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-bind="attrs" v-on="on" readonly
                            ref="mylink"
                            @click="copyToClipboard"
                            :value="'https://app.simiaroom.com/fa/panel/login?inviter='+profile.code"
                            style="direction: ltr;"
                            outlined>
              </v-text-field>

            </template>
            <span>{{ $t('$vuetify.invitationsPage.copyCode') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8" class="text-center">
          {{ $t('$vuetify.invitationsPage.subtitle') }}
        </v-col>
        <v-col cols="12" class="text-center">
          <ShareNetwork
              v-for="(network,index) in networks"
              :key="index"
              :network="network.title"
              :url="'https://app.simiaroom.com/fa/panel/login?inviter='+profile.code"
              :title="network.text"
              :description="network.description"
              :quote="network.quote"
              hashtags="simiaroom,simiaservice"
          >
            <v-btn
                fab class="px-0 ma-1" :class="network.color" small>
              <v-icon class="">{{ network.icon }}</v-icon>
            </v-btn>
          </ShareNetwork>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <v-data-table
                  dense
                  hide-default-footer
                  :headers="headers"
                  :items="content"
              >
                <template v-slot:top>
                  <div class="ma-2 pa-2 title">
                                        <span>
                                            {{ $t('$vuetify.invitationsPage.table.title') }}
                                        </span>
                  </div>
                  <v-divider></v-divider>
                </template>
                <template v-slot:item="{item}">

                  <tr>
                    <td>
                      <v-avatar size="35">
                        <v-icon>
                          fas fa-user
                        </v-icon>
                      </v-avatar>
                    </td>
                    <td class="text-center">{{ item.email }}</td>
                    <td class="text-center">
                      <template v-if="item.state==1">
                        20
                        {{ $t('$vuetify.invitationsPage.table.dollar') }}

                      </template>
                      <template v-else>
                        0
                      </template>
                    </td>
                    <td class="text-center">
                      <v-chip small
                              :class="{'green white--text':item.state ==1 ,'red white--text':item.state==0 }">
                        {{
                          item.state == 0 ? $t('$vuetify.invitationsPage.table.paymentStatus[0]') : $t('$vuetify.invitationsPage.table.paymentStatus[1]')
                        }}
                      </v-chip>
                    </td>
                  </tr>
                  <div>

                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <v-pagination
              v-model="pagination.page"
              :length="pagination.total"
          ></v-pagination>

        </v-col>

      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  created() {
    this.getInvitees({page: this.pagination.page});
  },
  data() {
    return {
      networks: [
        {
          title: 'email',
          text: '',
          icon: 'mdi-email',
          url: '',
          description: 'لبت خندون، دلت آروم\n' +
              'سیمیاروم؛ یه تجربه خوب از مشاوره آنلاین روانشناسیه، با حرفه‌ای‌ترین روان‌شناس‌ها و روان‌کاوهای فارسی زبان.\n' +
              'حالِ من خیلی بهتره، تو هم حال دلتو خوب کن',
          quote: 'quote',
          hashtags: 'hashtags',
          color: 'blue white--text'
        },
        {
          title: 'facebook',
          text: '',
          icon: 'mdi-facebook',
          url: '',
          description: 'description',
          quote: 'لبت خندون، دلت آروم\n' +
              'سیمیاروم؛ یه تجربه خوب از مشاوره آنلاین روانشناسیه، با حرفه‌ای‌ترین روان‌شناس‌ها و روان‌کاوهای فارسی زبان.\n' +
              'حالِ من خیلی بهتره، تو هم حال دلتو خوب کن',
          hashtags: 'simiaroom',
          color: 'blue white--text'
        },
        {
          title: 'facebook',
          icon: 'mdi-facebook-messenger',
          url: '',
          description: 'لبت خندون، دلت آروم\n' +
              'سیمیاروم؛ یه تجربه خوب از مشاوره آنلاین روانشناسیه، با حرفه‌ای‌ترین روان‌شناس‌ها و روان‌کاوهای فارسی زبان.\n' +
              'حالِ من خیلی بهتره، تو هم حال دلتو خوب کن',
          text: 'لبت خندون، دلت آروم\n' +
              'سیمیاروم؛ یه تجربه خوب از مشاوره آنلاین روانشناسیه، با حرفه‌ای‌ترین روان‌شناس‌ها و روان‌کاوهای فارسی زبان.\n' +
              'حالِ من خیلی بهتره، تو هم حال دلتو خوب کن',
          hashtags: 'simiaroom',
          color: 'blue white--text'
        },
        {
          title: 'twitter',
          text: 'لبت خندون، دلت آروم\n' +
              'سیمیاروم؛ یه تجربه خوب از مشاوره آنلاین روانشناسیه، با حرفه‌ای‌ترین روان‌شناس‌ها و روان‌کاوهای فارسی زبان.\n' +
              'حالِ من خیلی بهتره، تو هم حال دلتو خوب کن',
          icon: 'mdi-twitter',
          url: '',
          description: 'description',
          quote: 'quote',
          hashtags: 'hashtags',
          color: 'blue white--text lighten-1'
        },
        {
          title: 'telegram',
          text: '',
          icon: 'mdi-telegram',
          url: '',
          description: 'لبت خندون، دلت آروم\n' +
              'سیمیاروم؛ یه تجربه خوب از مشاوره آنلاین روانشناسیه، با حرفه‌ای‌ترین روان‌شناس‌ها و روان‌کاوهای فارسی زبان.\n' +
              'حالِ من خیلی بهتره، تو هم حال دلتو خوب کن',
          quote: 'quote',
          hashtags: 'hashtags',
          color: 'blue white--text darken-1'
        },
        {
          title: 'whatsapp',
          text: '',
          icon: 'mdi-whatsapp',
          url: '',
          description: 'لبت خندون، دلت آروم\n' +
              'سیمیاروم؛ یه تجربه خوب از مشاوره آنلاین روانشناسیه، با حرفه‌ای‌ترین روان‌شناس‌ها و روان‌کاوهای فارسی زبان.\n' +
              'حالِ من خیلی بهتره، تو هم حال دلتو خوب کن',
          quote: 'quote',
          hashtags: 'hashtags',
          color: 'green white--text'
        },
      ],
      pagination: {
        page: 1,
        total: 1
      },
      headers: [
        {text: '', value: 'avatar', sortable: false},
        // {text: 'توضیحات', value: 'desc', sortable: false},
        // {text: 'تاریخ خرید', value: 'date', sortable: true},
        // {text: 'نام', value: 'name'},
        // {text: 'نام خانوادگی', value: 'family'},
        {text: this.$t('$vuetify.invitationsPage.table.headers[0]'), value: 'email', align: 'center'},
        {text: this.$t('$vuetify.invitationsPage.table.headers[1]'), value: 'amount', align: 'center'},
        {text: this.$t('$vuetify.invitationsPage.table.headers[2]'), value: 'state', align: 'center'},
      ],
      content: [],
      slides: [
        {
          src: require('../../assets/1.gif'),
        },
        {
          src: require('../../assets/2.gif'),
        },
        {
          src: require('../../assets/3.gif'),
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      profile: 'getUserInfo'
    }),
  },
  methods: {
    copyToClipboard() {
      let textToCopy = this.$refs.mylink.$el.querySelector('input');
      textToCopy.select();
      document.execCommand("copy");
    },
    getInvitees(data) {
      this.loading = true;
      this.$store
          .dispatch('getInvitees', {
            page: data.page
          })
          .then((data) => {
            this.content = data.data.invitees;
            this.pagination.total = data.data.pagination.total_pages;
          }).finally(() => {
        this.loading = false;
      })
    },
    pageHandle(pagination) {
      this.getInvitees({
        page: pagination
      })
    },
  },
  watch: {
    'pagination.page'() {
      this.getInvitees({page: this.pagination.page});
    }
  }
}
</script>

<style scoped>

</style>